function updateCountDown() {
  const countDownDate = new Date('May 14, 2024 15:00:00').getTime()
  // Get todays date and time
  const now = new Date().getTime()

  // Find the diff between now an the count down date
  const diff = countDownDate - now

  // if countdown underflows to negative numbers, hide it
  document.getElementById('section-countdown').hidden = diff < 0

  // Time calculations for days, hours, minutes and seconds
  const days = Math.floor(diff / (1000 * 60 * 60 * 24))
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
  //skoňování data
  let daysText
  if (days === 1) {
      daysText = days + '&nbsp;den'
  } else if (days >= 2 && days <= 4) {
      daysText = days + '&nbsp;dny'
  } else {
      daysText = days + '&nbsp;dní'
  }

  let hoursText
  if (hours === 1) {
      hoursText = hours + '&nbsp;hodina'
  } else if (hours >= 2 && hours <= 4) {
      hoursText = hours + '&nbsp;hodiny'
  } else {
      hoursText = hours + '&nbsp;hodin'
  }

  let minutesText
  if (minutes === 1) {
      minutesText = minutes + '&nbsp;minuta'
  } else if (minutes >= 2 && minutes <= 4) {
      minutesText = minutes + '&nbsp;minuty'
  } else {
      minutesText = minutes + '&nbsp;minut'
  }

  document.getElementById('days').innerHTML = daysText
  document.getElementById('hours').innerHTML = hoursText
  document.getElementById('minutes').innerHTML = minutesText
}

document.addEventListener("DOMContentLoaded", () => {
  updateCountDown()
  setInterval(updateCountDown, 1000)
})
